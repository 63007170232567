// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-de-js": () => import("./../../../src/pages/de.js" /* webpackChunkName: "component---src-pages-de-js" */),
  "component---src-pages-en-js": () => import("./../../../src/pages/en.js" /* webpackChunkName: "component---src-pages-en-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-templates-blog-de-js": () => import("./../../../src/templates/blog_de.js" /* webpackChunkName: "component---src-templates-blog-de-js" */),
  "component---src-templates-blog-en-js": () => import("./../../../src/templates/blog_en.js" /* webpackChunkName: "component---src-templates-blog-en-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-project-js": () => import("./../../../src/templates/project.js" /* webpackChunkName: "component---src-templates-project-js" */),
  "component---src-templates-projects-de-js": () => import("./../../../src/templates/projects_de.js" /* webpackChunkName: "component---src-templates-projects-de-js" */),
  "component---src-templates-projects-en-js": () => import("./../../../src/templates/projects_en.js" /* webpackChunkName: "component---src-templates-projects-en-js" */),
  "component---src-templates-projects-js": () => import("./../../../src/templates/projects.js" /* webpackChunkName: "component---src-templates-projects-js" */)
}

